import { computePosition, autoUpdate, offset, shift, flip, arrow } from "@floating-ui/dom";
import { storePopup } from '@skeletonlabs/skeleton';
storePopup.set({ computePosition, autoUpdate, offset, shift, flip, arrow });

export const prerender = true;

export const load = async ({ url }) => {
  const data = {
    url: url.href,
  };
  return data;
};
